<template>
  <div id="landmarks">
    <div class="container-fluid">
      <div class="add-section" v-if="canAccess(['store-commonQuestion' , 'update-commonQuestion'])">
        <b-modal id="question-modal" size="xl" :cancel-title="$t('close')" @hidden="closeModal" hide-header-close
                 :ok-title="$t('save')" :title="$t('sidebar.FAQ')" @ok="submit">
          <b-form class="add-form">
            <b-row>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('department') }}</label>
                  <b-form-select
                    v-model="payload.department_type"
                    :options="DepartmentTypesOptions"
                  >
                    <template #first>
                      <b-form-select-option value=""> {{ $t('department-select') }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
              <b-col sm="12" lg="4">
                <div class="feild">
                  <label class="font-weight-bold" for="">{{ $t('arabic-language') }}</label>
                  <b-form-input
                    class="mb-2"
                    type="text"
                    :placeholder="$t('question')"
                    v-model="payload.ar_question"
                    required
                  ></b-form-input>
                  <b-form-textarea
                    type="text"
                    rows="4"
                    :placeholder="$t('answer')"
                    v-model="payload.ar_answer"
                    required
                  ></b-form-textarea>
                </div>
              </b-col>
              <b-col sm="12" lg="4">
                <div class="feild">
                  <label class="font-weight-bold" for="">{{ $t('english-language') }}</label>
                  <b-form-input
                    type="text"
                    class="mb-2"
                    :placeholder="$t('question')"
                    v-model="payload.en_question"
                    required
                  ></b-form-input>
                  <b-form-textarea
                    type="text"
                    rows="4"
                    :placeholder="$t('answer')"
                    v-model="payload.en_answer"
                    required
                  ></b-form-textarea>
                </div>
              </b-col>
              <b-col sm="12" lg="4">
                <div class="feild">
                  <label class="font-weight-bold" for="">{{ $t('kurdish-language') }}</label>
                  <b-form-input
                    type="text"
                    class="mb-2"
                    :placeholder="$t('question')"
                    v-model="payload.kr_question"
                    required
                  ></b-form-input>
                  <b-form-textarea
                    type="text"
                    rows="4"
                    :placeholder="$t('answer')"
                    v-model="payload.kr_answer"
                    required
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <div class="lang-toggle d-flex align-items-center justify-content-end mb-4">
        <b-button v-if="canAccess(['store-commonQuestion'])" variant="primary" @click="addInit"
                  class="d-flex align-items-center" style="gap: 5px;" size="sm">
          {{ $t('new-question') }} <i class="ri-add-line"></i></b-button>
      </div>
      <div class="accordion-container"
           v-if="canAccess(['get-commonQuestion','update-commonQuestion','show-commonQuestion','delete-commonQuestion'])">
        <div class="accoridon py-2 px-3 mb-3 rounded border border-primary"
             :class="[question.active ? 'active' : '',`question-${index}`]"
             v-for="(question,index) in question.data" :key="question.id">
          <div class="accoridon-head">
            <b-row>
              <b-col cols="8">
                <h5>
                  {{ question[`${$i18n.locale}_question`] }}
                </h5>
              </b-col>
              <b-col cols="4">
                <div class="actions d-flex align-items-center justify-content-end">
                  <b-button v-if="canAccess(['show-commonQuestion'])" v-b-tooltip.top="$t('show-data')"
                            variant=" iq-bg-primary" class="show-p" @click="show(index)" size="md"><i
                    class="ri-add-line w-100 h-100 p-0"></i></b-button>
                  <b-button v-if="canAccess(['update-commonQuestion'])" v-b-tooltip.top="$t('edit')"
                            variant=" iq-bg-success" @click="update(question)" class="mx-1" size="md"><i
                    class="ri-edit-line p-0"></i></b-button>
                  <!-- <b-button v-if="canAccess(['delete-commonQuestion'])" v-b-tooltip.top="$t('remove')"
                            variant=" iq-bg-danger" size="md" @click="remove(question.id)"><i
                    class="ri-delete-bin-line p-0"></i></b-button> -->
                </div>
              </b-col>
            </b-row>
          </div>
          <p class="mb-0">
            {{ question[`${$i18n.locale}_answer`] }}
          </p>
        </div>
      </div>
      <div class="pagination w-100 d-flex justify-content-center">
        <b-pagination
          v-model="filterData.page"
          :total-rows="question.total"
          :per-page="question.per_page"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { DepartmentTypes } from '@/enums'

export default {
  name: 'landmarks',
  data () {
    return {
      filterData: {
        page: 1
      },
      payload: {
        ar_question: '',
        en_question: '',
        kr_question: '',
        ar_answer: '',
        en_answer: '',
        kr_answer: '',
        department_type: ''
      }
    }
  },
  computed: {
    DepartmentTypesOptions () {
      return DepartmentTypes.asList().map(el => {
        return {
          text: this.$t(el.label),
          value: el.value
        }
      })
    }
  },
  methods: {
    show (index) {
      const accordion = document.querySelector(`.question-${index} `)
      accordion.classList.toggle('active')
    },
    filtering () {
      this.getQuestion(this.filterData)
    },
    addInit () {
      this.$bvModal.show('question-modal')
    },
    update (data) {
      Object.assign(this.payload, data)
      this.$bvModal.show('question-modal')
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeQuestion(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addQuestion(this.payload).then(() => {
          this.$bvModal.hide('question-modal')
          this.payload = this.resetObject(this.payload)
        })
      } else {
        this.updateQuestion(this.payload).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$bvModal.hide('question-modal')
        })
      }
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    }
  },

  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getQuestion(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
      this.authUserPermissions();
      this.getQuestion()
  }
}
</script>
<style>
.accordion-container .accoridon p {
  max-height: 0px;
  transition: max-height 0.5s;
  padding: 0px 10px;
  visibility: hidden;
}

.accordion-container .accoridon.active p {
  max-height: 1000px;
  padding: 20px 10px;
  visibility: visible;
}

.add-form .feild {
  padding: 10px;
}

.add-form .feild input,
.add-form .feild textarea {
  background-color: #f7f7f7;
}

</style>
